import React, { Component } from 'react'
import '../App.css'
import '../lib/scss/glass.scss'
// import '../lib/scss/loading.scss'

import GlassPage from '../pages/glass-page'
import Loading from '../components/loading'


function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 3000))
}

class GlassRoute extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render(){
    const { loading } = this.state

    if(loading) {
      return(
        <Loading
          Heading="Follow your Milk's Journey"
          Svg="/assets/loading/milk.svg"
        />
      );
    }
    return (
        <GlassPage />
    );
  }
}

export default GlassRoute
