import React from 'react'
import Logo from '../assets/logo.svg'
import { Link } from 'react-router-dom'

const Header = ({Return, LinkDestination}) => {

    if(Return) {
      return(
        <header>
          <Link id="backLink" to={LinkDestination}>&nbsp;</Link>
          <img src={Logo} alt="logo" />
        </header>
      )
    }
    return(
      <header>
        <img src={Logo} alt="logo" />
      </header>
    )
}

export default Header
