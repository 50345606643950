import React from 'react'
import Products from './products'
import HeroImg from '../../assets/metal/hero.png'
import Container from 'react-bootstrap/Container'


export default class MetalContent extends React.Component {
  render(){
    return(
      <div>
        <img width="100%" src={HeroImg} alt="Hero" />
        <Container>
          <div className="treatyourself">
            <h2>Treat yourself</h2>
            <h3>Your lipstick goes great with Summer Peach&nbsp;Mascara</h3>
          </div>
          <Products />
        </Container>
      </div>
    )
  }
}
