import firebase from 'firebase/app';

const FirebaseConfig = {
  apiKey: "AIzaSyA_x-7CwnvdqNg-Z4FuQ2XtmeIvZMh95f8",
  authDomain: "ldf-email-capture.firebaseapp.com",
  databaseURL: "https://ldf-email-capture.firebaseio.com",
  projectId: "ldf-email-capture",
  storageBucket: "ldf-email-capture.appspot.com",
  messagingSenderId: "254999733000",
  appId: "1:254999733000:web:7db4f188b5c76bfcea42bd"
}

const Firebase = firebase.initializeApp(FirebaseConfig);

export default Firebase
