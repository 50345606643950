import React from 'react'
import ReactVivus from 'react-vivus'
import Container from "react-bootstrap/Container";
import '../lib/scss/loading.scss'

const Loading = ({Heading, Svg}) => (
  <Container>
    <div className="loading">
      <h2>Thanks for scanning!</h2>
      <h3>{Heading}</h3>
    </div>

    <div className="loading-circle">
      <ReactVivus
        id="vivus"
        option={{
          file: `${Svg}`,
          animTimingFunction: 'EASE',
          type: 'oneByOne',
          onReady: console.log
        }}
        callback={console.log}
      />
    </div>
  </Container>
)

export default Loading
