import React from 'react'

import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button"
import Container from 'react-bootstrap/Container'

// import firebase from 'firebase/app';
import Firebase from '../config/firebase-config'
import 'firebase/auth';
import 'firebase/database';

import '../lib/scss/contact.scss'

const Contact = () => {

  let formMessage = Firebase.database().ref('ContactFormPage')

  function formSubmit(event) {
    event.preventDefault()

    //Reference for form collection(3)
    let title = document.querySelector('#contactFormPage-title').value
    let fname = document.querySelector('#contactFormPage-fname').value
    let lname = document.querySelector('#contactFormPage-lname').value
    let email = document.querySelector('#contactFormPage-email').value

    sendMessage(title, fname, lname, email)
    document.querySelector('#contactFormPage').reset()
    window.scrollTo(0, 0)
    document.querySelector('#contactFormPage h2').innerHTML = "Thanks!  We'll be in touch shortly"
    document.querySelector('#contactFormPage p').style.display = 'none'
  }

  function sendMessage(title, fname, lname, email){
    console.log('Message Sending');
    console.log('Email value', email);
    let newFormMessage = formMessage.push()
    newFormMessage.set({
      title: title,
      fname: fname,
      lname: lname,
      email: email
    })
  }

  return(
    <Container>
      <div className="contactHeading">
        <h1>Sustainable Stories</h1>
        <h4>presented by Nirvana CPH.<br/>
          Hosted by G . F Smith.</h4>
        <h4>Special thanks to SharpEnd, <br/>and Alistair Gibbs Studio.</h4>
      </div>

      <Form id="contactFormPage" onSubmit={formSubmit}>
        <h2>Find out more</h2>
        <p>Share your details and we'll be in touch soon</p>
        <Form.Group controlId="contactFormPage-title">
          <Form.Label>Title</Form.Label>
          <Form.Control as="select">
            <option defaultValue=""></option>
            <option value="mr">Mr</option>
            <option value="miss">Miss</option>
            <option value="mrs">Mrs</option>
            <option value="ms">Ms</option>
            <option value="dr">Dr.</option>
            <option value="prof">Prof.</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="contactFormPage-fname">
          <Form.Label>First Name</Form.Label>
          <Form.Control className="required" name="fname" type="text" required/>
        </Form.Group>

        <Form.Group controlId="contactFormPage-lname">
          <Form.Label>Last Name</Form.Label>
          <Form.Control className="required" name="lname" type="text" required/>
        </Form.Group>

        <Form.Group controlId="contactFormPage-email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control className="required" name="email" type="email" required/>
        </Form.Group>

        <label class="checkbox_container">I consent to my submitted data being collected and stored. <a href="https://wearesharpend.com/privacy-policy">Read our GDPR policies here</a>
          <input type="checkbox" value="gdpr" name="gdpr" id="gdpr" required/>
          <span class="checkmark"></span>
        </label>


        <button className="button button-text disabled" type="submit" id="formButton" >Submit</button>
      </Form>
    </Container>
  )
}

export default Contact
