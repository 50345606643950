import React from "react"
// import LoyaltyStamp from './LoyaltyStamp.js'
import StampStar from "../../assets/paper/stamp-star-circle.svg"
import StampCircle from "../../assets/paper/stamp-circle.svg"
import NewStampStar from "../../assets/paper/stamp-star.svg"

// import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Tada from 'react-reveal/Tada'

const LoyaltyStamps = () => {
  return (
    <div className="stamps">
      <Row>
        <Col xs={4}>
          <div className="stamp">
            <img src={StampStar} alt="stamp" />
          </div>
        </Col>
        <Col xs={4}>
          <div className="stamp">
            <img src={StampStar} alt="stamp" />
          </div>
        </Col>
        <Col xs={4}>
          <div className="stamp">
            <img src={StampStar} alt="stamp" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <div className="stamp">
            <img src={StampStar} alt="stamp" />
          </div>
        </Col>
        <Col xs={4}>
          <div className="stamp stamp-new">
            <Tada count={3}>
              <img src={NewStampStar} alt="stamp" />
              </Tada>
          </div>
        </Col>
        <Col xs={4}>
          <div className="stamp stamp-empty">
            <img src={StampCircle} alt="stamp" />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LoyaltyStamps;
