import React from "react";
import Container from "react-bootstrap/Container";

import Zoom from "react-reveal/Zoom";
import TextLoop from "react-text-loop";

import ShirtFolded from "../../assets/plastic/shirt_folded.svg";



const DataItem = ({ heading, data }) => {
  return (
    <Zoom>
      <div className="data">
        <h2>{heading}</h2>
        <h3>{data}</h3>
      </div>
    </Zoom>
  );
};

const MaterialDataItem = ({ heading, data }) => {
  return (
    <Zoom>
      <div className="data">
        <h1>{heading}</h1>
        <h3>{data}</h3>
      </div>
    </Zoom>
  );
};

const PackagingDataItem = ({ heading }) => {
  return (
    <div className="data">
      <h3>{heading}</h3>
        <h1>
        <TextLoop
          interval={1000}
          mask={true}
          children={["Cellophane",
            "Polypropylene (PP)",
            "Polyethylene/Polythene",
            "Polyactic Acid (PLA)",
            "Synthetic Self-Adhesive Seal Strip",
            "Paper Board",
            "Laminated Paper Board",
            "Polyethylene Terephthalate (PET)",
            "Polyvinyl Chloride (PVC)",
            "Stainless Steel",
            "Iron",
            "Aluminium",
            "Acrylic",
            "Nylon"
         ]}
        />
          </h1>
    </div>
  );
};

const DataPage = () => {
  // const [material, setMaterial] = React.useState('Cellophane') // needs a known value else will throw error

  // const materialsList = [
  //    "Cellophane",
  //    "Polypropylene (PP)",
  //    "Polyethylene/Polythene (PE, LDPE, HDPE)",
  //    "Polyactic Acid (PLA)",
  //    "Synthetic Self-Adhesive Seal Strip",
  //    "Paper Board",
  //    "Laminated Paper Board",
  //    "Polyethylene Terephthalate (PET)",
  //    "Polyvinyl Chloride (PVC)",
  //    "Stainless Steel",
  //    "Iron",
  //    "Aluminium",
  //    "Acrylic",
  //    "Nylon"
  // ]

   // materialsList.forEach(function (value) {
   //   // setTimeout(() => {
   //     setMaterial(value)
   //   // }, 500);
   //   console.log('material: ', value)
   // });


  return (
    <Container className="dataPage">
      <h1 className="dataPage-heading">
        Items Scanned
      </h1>
      <img className="dataPage-image" src={ShirtFolded} alt="logo" />
      <Zoom>
        <h1 className="dataPage-productHeading">Oxford Shirts</h1>
        <h2 className="units">5 Units</h2>
      </Zoom>

      <div className="dataGroups">
        <div className="dataGroup productDetails">
          <DataItem heading="Order no." data="5384800" />
          <DataItem heading="Colour" data="White" />
          <DataItem heading="Fit" data="Fitted" />
          <DataItem heading="Collar Size" data="16 inches" />
          <DataItem heading="Sleeve Length" data="35 inches" />
        </div>

        <div className="dataGroup materialData">
          <MaterialDataItem heading="20%" data="Acrylic" />
          <MaterialDataItem heading="30%" data="Polyester" />
          <MaterialDataItem heading="50%" data="Cotton" />
        </div>

        <div className="dataGroup materialData">
          <PackagingDataItem
            heading="Materials In The Packaging"
          />
        </div>

        <div className="dataGroup manufactureData">
          <Zoom>
            <h3>Manufactured in</h3>
            <h1>Taiwan</h1>
            <h3>23.02.19</h3>
          </Zoom>
        </div>

        <div className="dataGroup distanceData">
          <Zoom>
            <h2>6,134</h2>
            <h3>Miles Travelled</h3>
          </Zoom>
        </div>

        <div className="dataGroup distributorData">
          <Zoom>
            <h3>Reached Distributor On</h3>
            <h3>12.04.19</h3>
          </Zoom>
        </div>

        <div className="dataGroup storeData">
          <Zoom>
            <h3>Arrived in store</h3>
            <h3>01.05.19</h3>
          </Zoom>
        </div>
      </div>
    </Container>
  );
};

export default DataPage;
