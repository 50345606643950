import React from 'react'
import Container from 'react-bootstrap/Container'
import LoyaltyStamps from './loyaltyStamps'
import Impact from './impact'


const PaperContent = () => {
    return(
      <Container>
        <div className="heading">
          <h2>Your Rewards</h2>
          <h3>Keep recycling! One more cup for a free&nbsp;coffee!</h3>
        </div>
        <LoyaltyStamps />
        <Impact />
      </Container>
    )
}

export default PaperContent
