import React from 'react'

const MapContainer = ({MapImage, SubTitle, SubTitleData}) => {
  return(
    <div className="GlassContentType MapContainer">
      <img src={MapImage} alt="map" />
      <div className="containerText">
        <h4>{SubTitle} <span>{SubTitleData}</span></h4>
      </div>
    </div>
  )
}

const ImageContainer = ({Title, SubTitle, SubTitleData, Image, Text}) => {
  return(
    <div className="GlassContentType ImageContainer">
      <img src={Image} alt="cow" />
      <div className="containerText">
        <h3>{Title}</h3>
        <h4>{SubTitle} <span>{SubTitleData}</span></h4>
        {Text}
      </div>
    </div>
  )
}

const Date = ({Date}) => {
  return(
    <div className="GlassContentType DateContainer">
      <div className="containerText">
        <h4>Bottled On <span>{Date}</span></h4>
      </div>
    </div>
  )
}

export {
  MapContainer,
  ImageContainer,
  Date
}
