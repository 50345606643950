import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import GlassContent from '../components/glass/glass-content'
// import '../lib/scss/glass.scss'

export default class GlassPage extends React.Component {
  render(){
    return(
      <div className="glass">
        <Header />
        <GlassContent />
        <Footer />
      </div>
    )
  }
}
