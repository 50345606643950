import React, { Component } from "react";
import ScanMe from "../components/plastic/scanme";
import DataPage from "../components/plastic/datapage";
import Footer from "../components/footer";
import Header from "../components/header";

import IdleTimer from 'react-idle-timer'

import "../lib/scss/plastic.scss";


class PlasticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScanned: false,
      startAnimation: false
    };

    this.startAnimation = this.startAnimation.bind(this);
    this.handleScanMe = this.handleScanMe.bind(this);
    this.reset = this.reset.bind(this);

    // For idle timer
    this.idleTimer = null
  }

  startAnimation() {
    console.log(this.state);
    this.setState({ startAnimation: true });
    setTimeout(() => {
      this.handleScanMe();
    }, 5000);
  }

  handleScanMe() {
    console.log("triggered");
    this.setState({
      isScanned: true
    });
  }
  reset() {
    console.log("reset");
    this.setState({
      isScanned: false,
      startAnimation: false
    });
  }

  render() {
    const { isScanned, startAnimation } = this.state;

    return isScanned ? (
      <div className="plasticData">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onIdle={this.reset}
          timeout={25000} />
        <Header />
        <div id="backButton" onClick={this.reset}></div>
        <DataPage />
        <Footer />
      </div>
    ) : (
      <div className="plastic">
        <ScanMe
          startAnimation={startAnimation}
          onClick={this.startAnimation}
          scanMe={this.handleScanMe}
        />
      </div>
    );
  }
}
export default PlasticPage;
