import React, { Component } from 'react'
import '../App.css'
import Header from '../components/header'
import Footer from '../components/footer'
import Contact from '../components/contact'



class PaperRoute extends Component {
  render(){
    return(
        <div className="contactPage">
          <Header />
          <Contact />
          <Footer />
        </div>
      );
  }
}

export default PaperRoute
