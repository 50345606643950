import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import MetalContent from '../components/metal/metal-content'

export default class MetalPage extends React.Component {
  render(){
    return(
      <div className="metal">
        <Header />
        <MetalContent />
        <Footer />
      </div>
    )
  }
}
