import React from 'react'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {ProductData} from './data.json'
import Header from '../header'
import Footer from '../footer'

import Container from 'react-bootstrap/Container'



const Thanks = ({match}) => {
  window.scroll(0,0)

  console.log('Thanks page')
  function DeliveryDate() {
    var today = new Date();
    var dd = String(today.getDate() + 2).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return today = dd + '/' + mm + '/' + yyyy;
  }
  return(
    <div>
      <Header Return LinkDestination="/metal/"/>
      <Container className="metal-thanks">
        <img src={ProductData[match.params.id].image} alt="Lipstick" />
        <h1>Thanks for ordering</h1>
        <h3>
          Your {ProductData[match.params.id].title} is on its way and will be delivered to you on <DeliveryDate />
        </h3>
      </Container>
      <Footer />
    </div>
  )
}

export default Thanks
