import React, { Component } from 'react'
import '../App.css'
import '../lib/scss/core.scss'
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.svg'


class HomeRoute extends Component {
  render(){
    return(
        <div id="homePage">
          <h1>Sustainable Stories</h1>
          <img src={Logo} alt="logo" />
          <h3>Scan a product to<br/>find out more...</h3>
          <Link id="secretButton" to="/plastic/">&nbsp;</Link>
        </div>
    )
  }
}

export default HomeRoute
