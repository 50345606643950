import React from "react"

import Container from "react-bootstrap/Container"

import Fade from "react-reveal/Fade"

import FarmerImg from "../../assets/glass/farmer.svg"
import LocationImg from "../../assets/glass/location.svg"
import MilkImg from "../../assets/glass/cow.svg"
import BottlesImg from "../../assets/glass/bottles.svg"
import VehicleImg from "../../assets/glass/vehicle.svg"
import MilkGlass from "../../assets/glass/milkGlass.svg"
import TimelinePoint from "../../assets/timeline_point.svg"


import * as GlassContentTypes from "./glass-contenttypes"

const GlassContent = () => {
  return (
    <Container>
      <div className="heading">
        <h2>Your Milk's Journey</h2>
        <h3>See how your milk got to you</h3>
      </div>
      <div className="contentWrapper">
        <div id="timelineLine"></div>
        <div className="timelineContentItem">
          <Fade delay={1000}><div className="timelinePoint"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>
          <Fade up delay={1000}>
            <GlassContentTypes.ImageContainer
              Title="The Farmer"
              SubTitle="Name"
              SubTitleData="Ian Little"
              Image={FarmerImg}
              Text="Ian’s family have been farming dairy cows for over 50 years. He gets up at 4am every day to make sure the cows are healthy and ready to be milked. There’s a lot to do to make sure the milk gets to your door…"
            />
          </Fade>
        </div>
        <div className="timelineContentItem">
          <Fade><div className="timelinePoint"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>
          <Fade up>
            <GlassContentTypes.MapContainer
              MapImage={LocationImg}
              SubTitle="Location"
              SubTitleData="Hertfordshire"
            />
          </Fade>
        </div>
        <div className="timelineContentItem">
          <Fade><div className="timelinePoint"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>

          <Fade up>
            <GlassContentTypes.ImageContainer
              Title="The Milk"
              SubTitle="Cow Breed"
              SubTitleData="Holstein-Friesian"
              Image={MilkImg}
              Text="The Holstein-Friesian cow produce around 22 litres of milk every day. Their diet, feeding off the land, gives the milk a rich, creamy taste with a silky mouth-feel. It is pasteurised, bottled and delivered in less than 24 hours, just in time for breakfast."
            />
          </Fade>
        </div>
        <div className="timelineContentItem">
          <Fade><div className="timelinePoint noImage"></div></Fade>

          <Fade up>
            <GlassContentTypes.Date Date="19 / 09 / 19" />
          </Fade>
        </div>
        <div className="timelineContentItem">
          <Fade><div className="timelinePoint"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>

          <Fade up>
            <GlassContentTypes.ImageContainer
              Title="The Bottle"
              SubTitle="First Used"
              SubTitleData="11 / 04 / 19"
              Image={BottlesImg}
              Text="The glass bottle goes on a long and exciting journey throughout its life. Before reaching your door it could have been used up to 25 times visiting many homes along the way. In between each use the bottles are washed and sterilised to make sure they’re safe for consumption."
            />
          </Fade>
        </div>
        <div className="timelineContentItem">
          <Fade><div className="timelinePoint"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>

          <Fade up>
            <GlassContentTypes.ImageContainer
              Title="The Milk Float"
              SubTitle="No. of Journeys"
              SubTitleData="2345"
              Image={VehicleImg}
              Text="One of the first regularly used electric vehicles, the milk float that delivers your milk produces no greenhouse gases. It is perfectly designed to make sure milk can be transported and distributed in the easiest, quickest and most secure way."
            />
          </Fade>
        </div>
        <div className="timelineContentItem milkGlass">
          <Fade><div className="timelinePoint" id="timelinePoint-lastChild"><img src={TimelinePoint} alt="Timeline Point" /></div></Fade>

          <Fade up>
            <GlassContentTypes.ImageContainer
              Title="It's made it's way to you. Enjoy!"
              Image={MilkGlass}
            />
          </Fade>
        </div>
      </div>
    </Container>
  );
};

export default GlassContent;
