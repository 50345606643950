import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import HomeRoute from './routes/homeRoute'
import MetalRoute from './routes/metalRoute'
import GlassRoute from './routes/glassRoute'
import PaperRoute from './routes/paperRoute'
import PlasticRoute from './routes/plasticRoute'
import ContactPage from './pages/contactPage'
import './App.css'


class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

function App() {
  return (

    <BrowserRouter>
      <ScrollToTop>
      <Switch>
        <Route exact path="/" component={HomeRoute} />
        <Route path="/metal" component={MetalRoute} />
        <Route path="/glass" component={GlassRoute} />
        <Route path="/paper" component={PaperRoute} />
        <Route path="/plastic" component={PlasticRoute} />
        <Route path="/contact" component={ContactPage} />
      </Switch>
    </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
