import React, { Component } from 'react'
import '../App.css'
import '../lib/scss/plastic.scss'
import PlasticPage from '../pages/plastic-page'

class PlasticRoute extends Component {
  render(){
    return (
        <PlasticPage />
    );
  }
}

export default PlasticRoute
