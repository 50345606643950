import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import '../App.css'
import '../lib/scss/metal.scss'
// import '../lib/scss/loading.scss'
import MetalPage from '../pages/metal-page'
import ThanksPage from '../components/metal/thanks'
import Loading from '../components/loading'


function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 3000))
}

class MetalRoute extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render(){
    const { loading } = this.state

    if(loading) {
      return(
        <Loading
          Heading="Refill your lipstick"
          Svg="/assets/loading/lipstick_animated.svg"
        />
      );
    }
    return (
      <Switch>
        <Route exact path="/metal/" component={MetalPage} />
        <Route path="/metal/thanks/:id" component={ThanksPage} />
      </Switch>
    );
  }
}

export default MetalRoute
