import React from 'react'
import CountUp from 'react-countup'
import Fade from 'react-reveal/Fade'

const Impact = () => {
    let cupsToday

    // function CupsToday() {
      const now = new Date();
      let hoursToday = now.getHours()
      let minutesToday = ((hoursToday * 60) + now.getMinutes())
      cupsToday = minutesToday * 4861
      // cupsToday = cupsToday.toLocaleString()
      // return cupsToday
      console.log(cupsToday)
    // }

    return(
      <Fade up>
      <div className="impact">
        <h2>Your Impact</h2>
        <div className="impact-cupsUsedToday">
          <h1>
            <CountUp
              end={cupsToday}
              separator=","
              delay={2}
              duration={3}
              />
          </h1>
          <h3>Cups Used In The UK Today</h3>
        </div>
        <div className="impact-recycledToday">
          <h1>
            <CountUp
            end={190031}
            separator=","
            delay={2}
            duration={3}
            />
          </h1>
          <h3>Recycled Today</h3>
        </div>
      </div>
      </Fade>
    )
}

export default Impact
