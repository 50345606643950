import React, { useState } from "react";
import ProductItem from "./ProductItem";

import Fade from 'react-reveal/Fade'

import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";
import { ProductData } from "./data.json";

const MetalProducts = () => {
  const [show, setShow] = useState(false);
  const [productDataSlug, setProduct] = useState("magnificent_blue"); // needs a known value else will throw error

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleShow = ({ productDataProp }) => {
    // alert("passed data is: " + productDataProp)
    setShow(true);
    setProduct(productDataProp);
  };

  return (
    <div className="products">
      <Fade up>
        <h2>Refill your Lipstick</h2>
        <div className="product_items">
          <Row>
            <Col xs={6}>
              <ProductItem
                onClick={handleShow}
                productDataProp="magnificent_blue"
              />
            </Col>
            <Col xs={6}>
              <ProductItem
                onClick={handleShow}
                productDataProp="summer_peach"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <ProductItem
                onClick={handleShow}
                productDataProp="sunkissed_orange"
              />
            </Col>
            <Col xs={6}>
              <ProductItem
                onClick={handleShow}
                productDataProp="midnight_brown"
              />
            </Col>
          </Row>
        </div>
      </Fade>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <img src={ProductData[productDataSlug].image} alt="Lipstick" />
          <div>
            <h3>Order {ProductData[productDataSlug].title}</h3> for{" "}
            {ProductData[productDataSlug].price}?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to='#' onClick={handleClose}>
            <h3>Close</h3>
          </Link>
          <Link to={`/metal/thanks/${productDataSlug}`}>
            <h3>Order</h3>
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MetalProducts;
