import React from 'react'
import {ProductData} from './data.json'

const ProductItem = ({productDataProp, onClick}) => {
  return(
    <div onClick={onClick.bind(null,{productDataProp})} className="product_item" >
      <img src={ProductData[productDataProp].image} alt="Lipstick" />
      <div className="product_info">
        <h4>{ProductData[productDataProp].title}</h4>
        <span className="product_price">{ProductData[productDataProp].price}</span>
      </div>
    </div>
  )
}

export default ProductItem
