import React from 'react'
import SELogo from '../assets/se_logo_white.png'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"

// import firebase from 'firebase/app';
import Firebase from '../config/firebase-config'
import 'firebase/auth';
import 'firebase/database';

const Footer = () => {

    let formMessage = Firebase.database().ref('footerContactForm')

    function formSubmit(event) {
      event.preventDefault()

      //Reference for form collection(3)
      let email = document.querySelector('.form-group input').value

      sendMessage(email)
      document.querySelector('#footerForm').reset()
      document.querySelector('.footer-text').innerHTML = "Thanks!  We'll be in touch shortly"
    }
    function sendMessage(email){
      console.log('Message Sending');
      console.log('Email value', email);
      let newFormMessage = formMessage.push()
      newFormMessage.set({
        email: email
      })
    }

    return(
      <footer>
        <div id="footerWithForm">
        <h2>Like what you see?</h2>
        <p className="footer-text">Send us an email and we’ll see how we can work&nbsp;together</p>
          <Form id="footerForm" onSubmit={formSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Button id="formButton" className="button button-text" variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
        <p className="footer-copyright">
          © Copyright Sharp End Partnership Ltd. © Nirvana Creative Production House. All Rights Reserved 2019.<br/>
          <a href="https://wearesharpend.com/privacy-policy/" >View our privacy policy</a>
        </p>
        <a href="https://wearesharpend.com/" className="poweredbySE">
          <span className="footer-poweredby">powered by</span> <img width="300" src={SELogo} alt="SE Logo" />
        </a>
      </footer>
    )
}

export default Footer
