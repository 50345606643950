import React from "react"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import Logo from "../../assets/logo.svg"
import ShirtFolded from "../../assets/plastic/shirt_folded.svg";


const ScanMe = ({onClick,startAnimation}) =>
  // constructor(props) {
  //   super(props);
  //   this.state = {startAnimation: false}
  // }
  // startAnimation() {
  //   this.setState({startAnimation: true});
  //   // setTimeout(
  //   //     function() {
  //   //         this.props.({position: 1});
  //   //     }
  //   //     .bind(this),
  //   //     5000
  //   // );
  //   this.props.scanMe(this.state.startAnimation);
  // }

  // render() {
    // console.log(this.state.startAnimation);
    // return (
      <Container className={startAnimation ? 'scanMe scanMeAnimate' : 'scanMe'}>
        <span id="ray_2" />
        <span id="ray_1" />
        <img className="logo" src={Logo} alt="logo" />
        <img id="shirt" src={ShirtFolded} alt="logo" />
        <h1>Are we just shirts?</h1>
        <Button onClick={onClick} className="button button-text">
          Scan Us
        </Button>
      </Container>
    // );
  // }


export default ScanMe;
