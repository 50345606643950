import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import PaperContent from '../components/paper/paper-content'
// import '../lib/scss/paper.scss'

export default class PaperPage extends React.Component {
  render(){
    return(
      <div className="paper">
        <Header />
        <PaperContent />
        <Footer />
      </div>
    )
  }
}
